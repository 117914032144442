import '../assets/style/sass/richEditor.scss';
import CustomFontFamilyDropdown from './CustomFontFamilyDropdown';

/** For chat page */
export const reactDraftWysiwygToolbarOptionsChat = (customUploadCallback: any, embedCallback: any) => ({
  options: ['emoji', 'inline', 'list'],
  inline: {
    options: ['bold', 'italic', 'strikethrough'],
    bold: { className: 'iconBorder myCustombold' },
    italic: { className: 'iconBorder myCustomItalic' },
    strikethrough: { className: 'iconBorder myCustomStrikethrough' }
  },
  list: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['unordered', 'ordered'],
    unordered: { className: 'iconBorder' },
    ordered: { className: 'iconBorder' }
  }
});

// /** For article page */
// export const reactDraftWysiwygToolbarOptionsarticle = (imageUpload: any, embedCallback: any) => ({
//   options: ['inline', 'list', 'fontFamily', 'image', 'embedded', 'emoji', 'textAlign', 'colorPicker', 'link'], //'fontSize',
//   inline: {
//     options: ['bold', 'italic', 'underline', 'strikethrough'],// 'monospace'
//     bold: { className: 'iconBorder myCustombold' },
//     italic: { className: 'iconBorder myCustomItalic' },
//     strikethrough: { className: 'iconBorder myCustomStrikethrough' },
//     underline: { className: 'iconBorder myCustomunderline' },
//     // monospace: { className: 'iconBosrder myCustommonospace' }
//     // fontSize: {
//     //   options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36],
//     //   className: 'bordered-option-classname',
//     // },
//   },
//   list: {
//     options: ['unordered', 'ordered', 'indent', 'outdent'],
//     unordered: { className: 'iconBorder' },
//     ordered: { className: 'iconBorder' }
//   },
//   textAlign: {
//     options: ['left', 'center', 'right', 'justify'],
//     left: { className: 'iconBorder' },
//     center: { className: 'iconBorder' },
//     right: { className: 'iconBorder' },
//     justify: { className: 'iconBorder' }
//   },
//   colorPicker: {
//     popupClassName: 'articletoolbar-modal',
//     className: 'iconBorder'
//   },
//   image: {
//     className: 'iconBorder',
//     urlEnabled: true,
//     uploadEnabled: true,
//     alignmentEnabled: true,
//     uploadCallback: imageUpload,
//     previewImage: true,
//     inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
//     alt: { present: false, mandatory: false },
//     defaultSize: {
//       height: 'auto',
//       width: 'auto'
//     },
//     popupClassName: 'articletoolbar-modal'
//   },
//   embedded: {
//     embedCallback: embedCallback,
//     popupClassName: 'articletoolbar-modal',
//     className: 'iconBorder'
//   },
//   emoji: {
//     popupClassName: 'articletoolbar-modal',
//     className: 'iconBorder myCustomEmoji'
//   },
//   link: {
//     options: ['link', 'unlink'],
//     defaultTargetOption: '_blank',
//     popupClassName: 'articletoolbar-modal height-auto'
//   }
// });

// /** For Task page */
// export const reactDraftWysiwygToolbarOptionstask = (imageUpload: any, embedCallback: any) => ({
//   options: ['inline', 'list', 'fontFamily', 'image', 'embedded', 'emoji', 'textAlign', 'colorPicker', 'link'], //'fontSize',
//   inline: {
//     options: ['bold', 'italic', 'underline', 'strikethrough'], // 'monospace'
//     bold: { className: 'iconBorder myCustombold' },
//     italic: { className: 'iconBorder myCustomItalic' },
//     strikethrough: { className: 'iconBorder myCustomStrikethrough' },
//     underline: { className: 'iconBorder myCustomunderline' },
//     // monospace: { className: 'iconBorder myCustommonospace' },
//   },

//   // fontSize: {
//   //   options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36],
//   //   className: 'bordered-option-classname',
//   // },

//   list: {
//     options: ['unordered', 'ordered', 'indent', 'outdent'],
//     unordered: { className: 'iconBorder' },
//     ordered: { className: 'iconBorder' }
//   },

//   fontFamily: {
//     options: ['Open Sans', 'Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
//     className: 'myFontFamilyClass',
//     component: CustomFontFamilyDropdown,
//     dropdownClassName: 'fontFamilydropdownClass',
//     defaultFontFamily: 'Open Sans'
//   },

//   textAlign: {
//     options: ['left', 'center', 'right', 'justify'],
//     left: { className: 'iconBorder' },
//     center: { className: 'iconBorder' },
//     right: { className: 'iconBorder' },
//     justify: { className: 'iconBorder' }
//   },

//   colorPicker: {
//     popupClassName: 'tasktoolbar-modal',
//     className: 'iconBorder'
//   },

//   /**for img upload */
//   image: {
//     inputAccept: 'image/jpeg,image/jpg,image/png',
//     uploadCallback: imageUpload,
//     previewImage: true,
//     popupClassName: 'tasktoolbar-modal',
//     className: 'iconBorder'
//   },
//   embedded: {
//     popupClassName: 'taskEmbedded-modal',
//     embedCallback: embedCallback,
//     className: 'iconBorder'
//   },
//   emoji: {
//     className: 'iconBorder myCustomEmoji',
//     popupClassName: 'tasktoolbar-modal'
//   },
//   link: {
//     options: ['link', 'unlink'],
//     defaultTargetOption: '_blank',
//     popupClassName: 'tasktoolbar-modal height-auto'
//   }
// });

const reactDraftWysiwygToolbarOptions = (
  imageUpload: any,
  embedCallback: any,
  config: {
    place?: 'article' | 'task' | 'default';
    excludeOptions?: string[];
  } = {
      place: 'default'
    }
) => {
  const { place = 'default', excludeOptions = [] } = config;
  /** Class */
  const popupClassName = place === 'article' ? 'articletoolbar-modal' : place === 'task' ? 'tasktoolbar-modal' : 'default-toolbar-modal';
  /** Embedded Popup ClassName */
  const embeddedPopupClassName = place === 'task' ? 'taskEmbedded-modal' : popupClassName;
  /** Image size */
  const defaultSize = place === 'article' ? { height: 'auto', width: 'auto' } : undefined;

  // Toolbar options
  const toolbarOptions = {
    options: [
      'inline',
      'list',
      // 'fontSize',
      'fontFamily',
      'image',
      'embedded',
      'emoji',
      'textAlign',
      'colorPicker',
      'link',
    ].filter((option) => !excludeOptions.includes(option)),

    inline: {
      options: ['bold', 'italic', 'underline', 'strikethrough'],
      bold: { className: 'iconBorder myCustombold' },
      italic: { className: 'iconBorder myCustomItalic' },
      strikethrough: { className: 'iconBorder myCustomStrikethrough' },
      underline: { className: 'iconBorder myCustomunderline' },
    },

    // fontSize: {
    //   options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36],
    //   className: 'bordered-option-classname',
    // },

    list: {
      options: ['unordered', 'ordered', 'indent', 'outdent'],
      unordered: { className: 'iconBorder' },
      ordered: { className: 'iconBorder' }
    },

    fontFamily: {
      options: ['Open Sans', 'Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
      className: 'myFontFamilyClass',
      component: CustomFontFamilyDropdown,
      dropdownClassName: 'fontFamilydropdownClass',
      defaultFontFamily: 'Open Sans'
    },

    textAlign: {
      options: ['left', 'center', 'right', 'justify'],
      left: { className: 'iconBorder' },
      center: { className: 'iconBorder' },
      right: { className: 'iconBorder' },
      justify: { className: 'iconBorder' }
    },

    colorPicker: {
      popupClassName: popupClassName,
      className: 'iconBorder'
    },

    image: {
      className: 'iconBorder',
      urlEnabled: true,
      uploadEnabled: true,
      alignmentEnabled: true,
      uploadCallback: imageUpload,
      previewImage: true,
      inputAccept: 'image/jpeg,image/jpg,image/png',
      alt: { present: false, mandatory: false },
      defaultSize: defaultSize,
      popupClassName: popupClassName,
    },

    embedded: {
      embedCallback: embedCallback,
      popupClassName: embeddedPopupClassName,
      className: 'iconBorder',
    },

    emoji: {
      className: 'iconBorder myCustomEmoji',
      popupClassName: popupClassName,
      // emojis: [ ],
    },

    link: {
      options: ['link', 'unlink'],
      defaultTargetOption: '_blank',
      popupClassName: `${popupClassName} height-auto`,
    },
  };

  return toolbarOptions;
};

// Export toolbar configurations for different modes
export const reactDraftWysiwygToolbarOptionsarticle = (imageUpload: any, embedCallback: any) =>
  reactDraftWysiwygToolbarOptions(imageUpload, embedCallback, {
    place: 'article',
  });

export const reactDraftWysiwygToolbarOptionstask = (imageUpload: any, embedCallback: any) =>
  reactDraftWysiwygToolbarOptions(imageUpload, embedCallback, {
    place: 'task', // Task-specific configuration
  });
