import React, { useEffect, useState } from 'react';
import {
    Box,
    Link,
    Stack,
    Divider,
    Typography
} from '@mui/material';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { API } from '../../../../../api';
import { useNavigate } from 'react-router-dom';
import MySkeleton from '../../../../../components/Skeleton';
import { getUserDetails } from '../../../../../utils/orgName';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { CardStyle } from '../../../style-components/CardStyling';
import PreviewContent from '../../Program/components/PreviewContent';
import { Article } from '../../Program/components/program tabs/ContentSettings';
import useResponsiveScreenSwitch from '../../../../../utils/useResponsiveScreenSwitch';
import { useAppSelector } from '../../../../../redux/hooks';

interface HelpfulTipsProps {
    loader?: any;
    place?: string;
}

const HelpfulTips: React.FC<HelpfulTipsProps> = ({
    loader,
    place
}) => {
    const classes = CardStyle();
    const navigate = useNavigate();
    const { orgId, location } = getUserDetails();
    const { forMobile } = useResponsiveScreenSwitch();

    const [helpfulTips, setHelpfulTips] = useState<IArticelTips[]>([]);
    const [articleData, setArticleData] = useState<Article>();
    const [openPreview, setOpenPreview] = useState(false);
    const [articleDataLoading, setArticleDataLoading] = useState(false);
    const selectedGroupId = useAppSelector((state) => state.selectedGroup.data);
    const groupId = selectedGroupId.id || '';

    const getHelpfulTips = async () => {
        const response = await API.getHelpfulTips({ groupId: groupId, role: 'mentor', location });
        if (response.status === 200) {
            setHelpfulTips(response?.data?.data);
        }
    };

    useEffect(() => {
        getHelpfulTips();
    }, [groupId]);

    const handelOnClickPreviewArticleName = async (articleId: string, location: string) => {
        setArticleDataLoading(true);
        setOpenPreview(true);
        try {
            const response = await API.getAllArticleById({
                orgId: orgId,
                articleId: articleId,
                isContentSettings: false,
                articleLocation: location ? location : ''
            });

            if (response && response.status === 200) {
                setArticleData(response.data.article);
            }
        } catch (error) {
            toast.error(_.get(error, 'response.data.message', 'Something went wrong, please try again'));
            setOpenPreview(false);
        } finally {
            setArticleDataLoading(false);
        }
    };

    if (loader) {
        return <MySkeleton variant="rounded" height={forMobile ? 110 : 240} />;
    }

    /** Show only the first 4 tips */
    const displayedTips = helpfulTips?.slice(0, 4);

    return (
        <>
            <Box p={2} className={classes.cardBox} minHeight={forMobile ? 'auto' : '240px'}>
                <Stack mb={1} direction="row" justifyContent="space-between">
                    <Typography variant="h6">Getting Started</Typography>
                    {helpfulTips.length > 4 && (
                        <Link
                            fontWeight="600"
                            fontSize="12px"
                            color="#0082B6"
                            onClick={() => navigate('/app/help', { state: { helpfulTips } })}
                        >
                            View More
                        </Link>
                    )}
                </Stack>

                {helpfulTips.length === 0 ? (
                    <Typography textAlign="center" pt={forMobile ? 0 : 5}>
                        No materials added
                    </Typography>
                ) : (
                    displayedTips.map((ele, index) => (
                        <React.Fragment key={ele._id}>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                className="cursor-pointer"
                                onClick={() => handelOnClickPreviewArticleName(ele._id, ele.location)}
                            >
                                <Typography noWrap variant="subtitle1" color="primary">
                                    {ele?.articleName}
                                </Typography>
                                <ChevronRightIcon sx={{ color: '#ABB5BE' }} />
                            </Box>
                            {index !== displayedTips.length - 1 && <Divider sx={{ my: 0.5 }} />}
                        </React.Fragment>
                    ))
                )}
            </Box>

            {/* Content preview popup */}
            <PreviewContent
                openPreview={openPreview}
                setOpenPreview={setOpenPreview}
                singleListData={articleData}
                loading={articleDataLoading}
            />
        </>
    );
};


export default HelpfulTips;
