import React from 'react';
import { Box, Typography } from '@mui/material';

interface TagBoxProps {
    text: string;
    bgColor?: string;
    fontSize?: string;
    fontWeight?: number | string;
    borderRadius?: string;
    color?: string;
    my?: number;
}

const TagBox: React.FC<TagBoxProps> = ({
    text,
    bgColor = 'transparent',
    fontSize = '12px',
    fontWeight = '600',
    borderRadius = '5px',
    color = '#E99940',
    my = 1
}) => {
    return (
        <Box
            my={my}
            bgcolor={bgColor}
            width="fit-content"
            borderRadius={borderRadius}
            border={`1px solid ${color}`}
        >
            <Typography
                px={'9px'}
                py={'2px'}
                color={color}
                fontSize={fontSize}
                fontWeight={fontWeight}
                textTransform="capitalize"
            >
                {text}
            </Typography>
        </Box>
    );
};

export default TagBox;
