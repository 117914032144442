import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Radio,
  Button,
  Dialog,
  Divider,
  TextField,
  InputLabel,
  RadioGroup,
  Typography,
  FormControl,
  DialogActions,
  DialogContent,
  FormControlLabel
} from '@mui/material';
import CommonModalHeader from '../../../../../../../../components/DialogUi/CommonModalHeader';
import { ITaskLevels } from '../../../../../../../../redux/slices/tasksSlice';
import { API } from '../../../../../../../../api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { convertToEpoch } from '../../../../../../../../utils/commonFunctions';
import moment from 'moment';
import { levelTypes } from '../../../../../../../../utils/constants';

interface SetDateModalProps {
  openSetDateModal: boolean;
  handleopenSetDateModal: (flag: boolean) => void;
  level: ITaskLevels;
  setTempTasks: any;
  handleClosePopover: any;
}

const SetDateModal: React.FC<SetDateModalProps> = ({
  openSetDateModal,
  handleopenSetDateModal,
  level,
  setTempTasks,
  handleClosePopover
}) => {
  const [levelActive, setLevelActive] = useState<string>(levelTypes.activeNow);
  const [isAvailble, setIsAvailble] = useState<boolean>(false);
  const [endDate, setEndDate] = useState<string>(levelTypes.activeNow);
  const [dateValue, setDateValue] = useState<any>('');
  const [startDateValue, setStartDateValue] = useState<any>('');
  const [levelDescription, setLevelDescription] = useState<string>(level.levelDescription || '');
  const grpId = useParams().id || '';
  const handleSave = async () => {
    if (endDate === levelTypes.calendarDate && !dateValue) {
      toast.error('Please select a valid end date');
      return;
    }

    if (endDate === levelTypes.daysAfterSignup && (!dateValue || Number(dateValue) < 0)) {
      toast.error('Please enter valid number of days');
      return;
    }

    if (levelActive === levelTypes.calendarDate && !startDateValue) {
      toast.error('Please select a valid start date');
      return;
    }

    if (levelActive === levelTypes.daysAfterSignup && (!startDateValue || Number(startDateValue) < 0)) {
      toast.error('Please enter valid number of days');
      return;
    }

    const getValue = (type: string, value: any) => {
      if (type === levelTypes.calendarDate) {
        return convertToEpoch(new Date(value));
      } else if (type === levelTypes.daysAfterSignup) {
        return Number(value);
      } else {
        return 0;
      }
    };
    try {
      const data = {
        levelName: level.levelName,
        levelDescription: levelDescription,
        setStartDate: {
          type: levelActive,
          value: getValue(levelActive, startDateValue)
        },
        setEndDate: {
          type: endDate,
          value: getValue(endDate, dateValue)
        }
      };
      setTempTasks((prev: any) => {
        const newLevels = prev.levels.map((each: any) => {
          if (each._id === level._id) {
            return {
              ...each,
              levelDescription,
              startDate: {
                type: levelActive,
                value: getValue(levelActive, startDateValue)
              },
              endDate: {
                type: endDate,
                value: getValue(endDate, dateValue)
              }
            };
          }
          return each;
        });

        return { ...prev, levels: newLevels };
      });
      handleClose();
      if (!level.isNewLevel) {
        const response = await API.updateLevelDate({
          data,
          grpId,
          levelId: level._id || ''
        });
      }
    } catch (e) {
      toast.error('level failed to save');
    }
  };
  useEffect(() => {
    if (openSetDateModal) {
      setLevelActive(level.startDate && level?.startDate?.type ? level?.startDate?.type : levelTypes.activeNow);
      setLevelDescription(level.levelDescription || '');
      setEndDate(level.endDate && level?.endDate?.type ? level?.endDate?.type : levelTypes.activeNow);
      if (level.endDate && level?.endDate?.type === levelTypes.calendarDate) {
        setDateValue(moment.unix(level?.endDate?.value).format('YYYY-MM-DD'));
      } else {
        setDateValue(level.endDate?.value || '');
      }
      if (level.startDate && level?.startDate?.type === levelTypes.calendarDate) {
        setStartDateValue(moment.unix(level?.startDate?.value).format('YYYY-MM-DD'));
      } else {
        setStartDateValue(level.startDate?.value || '');
      }
    }
  }, [openSetDateModal]);
  const handleClose = () => {
    setLevelDescription('');
    setIsAvailble(false);
    handleopenSetDateModal(false);
    handleClosePopover();
  };
  return (
    <Dialog open={openSetDateModal} onClose={handleClose} fullWidth>
      {/* Dialog Header */}
      <CommonModalHeader title="Create Lightning Strike" handleCloseModal={handleClose} />

      <DialogContent>
        {/* Start Date */}
        <Box>
          <Typography variant="h6">Select Start Date</Typography>
          <Typography my={2}>
            This allows you to display this level as 'Locked' until a later date. While locked, members can see the
            Level name and description. Once activated, the Lessons will then be displayed and available for completion.
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup
              aria-labelledby="levelActiveHostLabel"
              id="levelActiveRadioGroup"
              name="levelActiveRadioGroup"
              value={levelActive}
              onChange={(e) => setLevelActive(e.target.value)} // Handle radio selection
            >
              <Grid container spacing={2}>
                <Grid item xs={12} display="flex" alignItems="center">
                  <FormControlLabel
                    value={levelTypes.activeNow}
                    control={<Radio />}
                    label=""
                    className="mr-0 px-10 radioAlign"
                  />
                  <Typography color={levelActive === levelTypes.activeNow ? 'primary' : 'secondary'}>
                    Make level active <b>now</b>
                  </Typography>
                </Grid>

                <Grid item xs={12} display="flex">
                  <FormControlLabel value={levelTypes.calendarDate} control={<Radio />} label="" className="mr-0" />
                  <Box flex={1}>
                    <Typography color={levelActive === levelTypes.calendarDate ? 'primary' : 'secondary'} mb={1}>
                      Make level active on a specific date:
                    </Typography>
                    <TextField
                      type="date"
                      fullWidth
                      disabled={levelActive !== levelTypes.calendarDate} // Enable when specific date option is selected
                      value={levelActive === levelTypes.calendarDate ? startDateValue : ''}
                      onChange={(e) => setStartDateValue(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} display="flex">
                  <FormControlLabel value={levelTypes.daysAfterSignup} control={<Radio />} label="" className="mr-0" />
                  <Box flex={1}>
                    <Typography mb={1} color={levelActive === levelTypes.daysAfterSignup ? 'primary' : 'secondary'}>
                      Make level active on a set number of days after signup:
                    </Typography>
                    <TextField
                      type="number"
                      value={levelActive === levelTypes.daysAfterSignup ? startDateValue : ''}
                      placeholder="example: 30"
                      fullWidth
                      disabled={levelActive !== levelTypes.daysAfterSignup}
                      InputProps={{ inputProps: { min: 0 } }}
                      onChange={(e) => setStartDateValue(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} display="flex">
                  <FormControlLabel
                    value={levelTypes.availableSoon}
                    control={<Radio />}
                    label=""
                    className="mr-0 px-10 radioAlign"
                  />
                  <Box flex={1}>
                    <Typography mb={1} color={levelActive === levelTypes.availableSoon ? 'primary' : 'secondary'}>
                      Activate Level at a later date
                    </Typography>
                    <Typography
                      fontStyle="italic"
                      color={levelActive === levelTypes.availableSoon ? 'primary' : 'secondary'}
                    >
                      If you choose this option, this level will be displayed as “Available soon” and will remain that
                      way until you make the level active via an option above.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        </Box>
        <Divider className="my-20" />

        {/* End Date */}
        <Box>
          <Typography variant="h6">Select End Date</Typography>
          <Typography my={2}>
            This setting allows you to select a date in which the module closes. Once this date passes, the level and
            lessons within it will no longer be available to users.
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup
              aria-labelledby="levelActiveHostLabel"
              id="levelActiveRadioGroup"
              name="levelActiveRadioGroup"
              value={endDate}
              onChange={(e) => {
                setDateValue('');
                setEndDate(e.target.value);
                setStartDateValue('');
              }} // Handle radio selection
            >
              <Grid container spacing={2}>
                <Grid item xs={12} display="flex" alignItems="flex-start">
                  <FormControlLabel
                    value={levelTypes.activeNow}
                    control={<Radio />}
                    label=""
                    className="mr-0 px-10 radioAlign"
                  />
                  <Typography color={endDate === levelTypes.activeNow ? 'primary' : 'secondary'}>
                    Does not have an end date
                    <br />
                    (Level will always be open as long as this program is open)
                  </Typography>
                </Grid>

                <Grid item xs={12} display="flex" alignItems="flex-start">
                  <FormControlLabel
                    value={levelTypes.calendarDate}
                    control={<Radio />}
                    label=""
                    className="mr-0 px-10 radioAlign"
                  />
                  <Box flex={1}>
                    <Typography color={endDate === levelTypes.calendarDate ? 'primary' : 'secondary'} mb={1}>
                      Close on a specific date:
                    </Typography>
                    <TextField
                      type="date"
                      fullWidth
                      value={endDate === levelTypes.calendarDate ? dateValue : ''}
                      disabled={endDate !== levelTypes.calendarDate}
                      onChange={(e) => setDateValue(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} display="flex" alignItems="flex-start">
                  <FormControlLabel
                    value={levelTypes.daysAfterSignup}
                    control={<Radio />}
                    label=""
                    className="mr-0 px-10 radioAlign"
                  />
                  <Box flex={1} mt={0.5}>
                    <Typography mb={1} color={endDate === levelTypes.daysAfterSignup ? 'primary' : 'secondary'}>
                      Close on a set number of days after it opens:
                    </Typography>
                    <TextField
                      type="number"
                      value={endDate === levelTypes.daysAfterSignup ? dateValue : ''}
                      placeholder="example: 30"
                      fullWidth
                      disabled={endDate !== levelTypes.daysAfterSignup}
                      InputProps={{ inputProps: { min: 0 } }}
                      onChange={(e) => setDateValue(e.target.value)}
                    />
                  </Box>
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        </Box>
        <Divider className="my-20" />

        {/* Level Description */}
        <Typography variant="h6" mb={1}>
          Level Description
        </Typography>
        <Typography>
          This description will be available to members while your Level is locked so that they have an understanding of
          what to expect when the Level is activated.
        </Typography>
        <Box mt={2}>
          <InputLabel>Level description</InputLabel>
          <TextField
            multiline
            minRows={4}
            name=""
            placeholder="Enter text here"
            value={levelDescription || ''}
            onChange={(e) => setLevelDescription(e.target.value)}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button fullWidth variant="contained" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SetDateModal;
