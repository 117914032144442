/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-duplicate-imports */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useCallback } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { Box, IconButton, Typography } from '@mui/material';
import { reactDraftWysiwygToolbarOptionsChat } from '../../../../utils/react-draft-wysiwyg-options';
import { Send } from '@mui/icons-material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { AtomicBlockUtils, EntityInstance, Modifier, convertToRaw } from 'draft-js';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';
import '../../../../assets/style/sass/richEditor.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../../../redux/hooks';
import { getCurrentChannelName } from '../../../../routes/helpers';
import { EditorState, Entity, SelectionState, ContentBlock, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
// import { chatEditorActions } from '../../../../redux/slices/chat-editor/chatEditorSlice';
import { useDispatch } from 'react-redux';
import { ChatStyle } from './ChatStyling';
import { toast } from 'react-toastify';
// import { ImageBlock } from '../Program/components/program tabs/ContentSettings';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import pdffile from '../../../../assets/images/pdf-file.svg';
import docfile from '../../../../assets/images/doc-file.svg';
import textfile from '../../../../assets/images/text-file.svg';
import excelfile from '../../../../assets/images/excel-file.svg';
import pptfile from '../../../../assets/images/ppt-file.svg';
import videofile from '../../../../assets/images/video-file.svg';
import unknownfile from '../../../../assets/images/unknown-file.svg';
import VideoPlayer from '../../components/VideoPlayer';
import { ChatEditorLoader } from '../../../../Loader/chat/ChatLoader';
import { handleImageCompression, imageSizeLimit } from '../../components/common';

export const FituredImage = (props: any) => {
  const classes = ChatStyle();
  const { src } = props.contentState.getEntity(props.entityKey).getData();
  return <img src={src} alt="Embedded" className={classes.featuredImage} />;
};

let fileType: any;

const ImageBlock = ({ block, contentState, blockProps }: { block: any; contentState: any; blockProps: any }) => {
  const entityKey = block.getEntityAt(0);
  const { setEditorState, setUpdateFile, setFile, setImageEntities, imageEntities } = blockProps.blockProps;
  const entity = entityKey !== null ? contentState.getEntity(entityKey) : null;

  const getDisplayName = () => {
    const contentType = entity.getData().type?.toLowerCase();
    const cleanedFileName = entity?.getData()?.name;

    switch (contentType) {
      case 'pdf':
        return {
          fileName: cleanedFileName,
          message: 'PDF Document',
          fileType: 'PDF',
          fileIcon: <img alt="" src={pdffile} />
        };
      case 'ppt':
        return {
          fileName: cleanedFileName,
          message: 'PPT Document',
          fileType: 'PPT',
          fileIcon: <img alt="" src={pptfile} />
        };
      case 'word':
        return {
          fileName: cleanedFileName,
          message: 'Word Document',
          fileType: 'word',
          fileIcon: <img alt="" src={docfile} />
        };
      case 'excel':
        return {
          fileName: cleanedFileName,
          message: 'Excel Document',
          fileType: 'excel',
          fileIcon: <img alt="" src={excelfile} />
        };
      case 'text':
        return {
          fileName: cleanedFileName,
          message: 'Text Document',
          fileType: 'text',
          fileIcon: <img alt="" src={textfile} />
        };
      case 'video':
        return {
          fileName: cleanedFileName,
          fileType: 'video',

          // message: 'Video File',
          // fileIcon: <img src={videofile} />,

          message: entity,
          fileIcon: ''
        };
      case 'image':
        return {
          fileName: cleanedFileName,
          message: entity,
          fileType: 'image',
          fileIcon: ''
        };
      default:
        return {
          fileName: cleanedFileName,
          message: 'Unknown Content Type',
          fileType: 'unknown',
          fileIcon: <img alt="" src={unknownfile} />
        };
    }
  };

  const removeBlock = () => {
    setUpdateFile('');
    setFile('');
    setImageEntities(false);
    const { editorState } = blockProps.blockProps;
    const selectionState = editorState.getSelection();
    const blockKey = block.getKey();
    const blockSelection = selectionState.merge({
      anchorKey: blockKey,
      focusKey: blockKey,
      anchorOffset: 0,
      focusOffset: block.getLength()
    });

    const updatedContentState = Modifier.removeRange(contentState, blockSelection, 'backward');

    // Update the editorState with the modified contentState
    let newEditorState = EditorState.push(editorState, updatedContentState, 'remove-range');
    const newState = EditorState.moveFocusToEnd(newEditorState);
    setEditorState(newState);
  };

  return (
    <Box className="image-block">
      {entityKey !== null ? (
        <>
          {entity.getType()?.toLowerCase() === 'image' || entity.getData().type?.toLowerCase() === 'image' ? (
            <Box width="110px" height="110px" position="relative">
              {/* {getDisplayName()} */}
              <img
                src={entity.getData().src}
                alt="Inserted content"
                width={'110px'}
                height={'110px'}
                className="objectFit-cover br-8"
              />
              <Box borderRadius={5} bgcolor="#262738B3" position="absolute" top="5px" right="5px">
                <IconButton onClick={removeBlock} sx={{ p: '5px' }}>
                  <CloseIcon sx={{ color: '#FFFFFF', width: '20px', height: '20px' }} />
                </IconButton>
              </Box>
            </Box>
          ) : entity.getType()?.toLowerCase() === 'video' || entity.getData().type?.toLowerCase() === 'video' ? (
            <Box width="110px" height="110px" position="relative">
              <VideoPlayer src={entity.getData().src} width="110px" height="110px" />
              <Box borderRadius={5} bgcolor="#262738B3" position="absolute" top="5px" right="5px">
                <IconButton onClick={removeBlock} sx={{ p: '5px' }}>
                  <CloseIcon sx={{ color: '#FFFFFF', width: '20px', height: '20px' }} />
                </IconButton>
              </Box>
            </Box>
          ) : (
            <Box
              display={'flex'}
              alignItems={'center'}
              border="1px solid #EFF0F4"
              p={1}
              min-width="200px"
              width="300px"
              borderRadius={'8px'}
              mt={1}
            >
              <Box pr={1}>{getDisplayName().fileIcon}</Box>

              <Box display={'flex'} flexDirection={'column'} width="200px">
                <Typography noWrap maxWidth="calc(100% - 10px)">
                  {/* {fileName} */}
                  {getDisplayName().fileName}
                </Typography>
                <Typography fontSize="10px" color="#ABB5BE">
                  {getDisplayName().message}
                </Typography>
              </Box>

              <Box>
                <IconButton onClick={removeBlock} sx={{ padding: '0' }}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          )}
        </>
      ) : null}
    </Box>
  );
};

interface IChatEditorComponent {
  editorState: any;
  setEditorState: React.Dispatch<any>;
  disableSendButton: boolean;
  setFile: any;
  file: any;
  isLoading: boolean;
  handleFileUpload: any;
  setUpdateFile: any;
  handlePostMessage: any;
  handleEditCancel: any;
  showEditCancel: boolean;
  setEditCancel: any;
  channelName: string;
  activeMessageId: any;
}
let threadValue = '';

const ChatEditorComponent: React.FC<IChatEditorComponent> = ({
  editorState: editorNew,
  setEditorState: setEditorNew,
  disableSendButton,
  handlePostMessage,
  setUpdateFile,
  setFile,
  file,
  isLoading,
  handleEditCancel,
  showEditCancel,
  channelName,
  activeMessageId
}) => {
  // const queryClient = useQueryClient();
  // const dispatch = useAppDispatch()
  const classes = ChatStyle();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [imageEntities, setImageEntities] = useState(false);
  const [editorState, setEditorState] = useState(editorNew);
  const content = convertToRaw(editorState.getCurrentContent());
  // const text = _.get(_.head(_.get(content, "blocks")), "text", "");
  const contentText = _.map(_.get(content, 'blocks'), (each) => _.get(each, 'text', '')).join('');
  const contentType = _.map(_.get(content, 'blocks'), (each) => _.get(each, 'type', '')).join('');
  const selectedChatUser = useAppSelector((state) => state.selectedChatUser.data);
  // const editorValue = useAppSelector(state => _.get(_.find(_.get(state,'chatEditorState'), each => _.get(each,'threadId') === threadValue),'value',EditorState.createEmpty()))

  const threadId = searchParams.get('threadid') || '';
  threadValue = threadId || '';
  const type = searchParams.get('type') || '';

  let hidePlaceholder = 'RichEditor-editor';
  let contentState = editorState.getCurrentContent();
  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== 'unstyled') {
      hidePlaceholder += ' RichEditor-hidePlaceholder';
    }
  }

  const handleFileUpload = async (event: any) => {
    const imgSize = imageSizeLimit();
    let file = event.target.files[0];
    const fileName: any = file.name.toLowerCase();
    if (file && fileName.match(/\.(jpg|jpeg|png|svg)$/)) {
      if (file.size > imgSize.limitValue) {
        toast.error('The max file size is 10MB.');
        return;
      }
      file = await handleImageCompression(event.target.files[0]);
    }

    if (file) {
      if (file.size > 50 * 1024 * 1024) {
        toast.error('The max file size is 50MB');
        return;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const fileDataUrl = e.target.result;
        let fileType = 'UNKNOWN';
        if (fileName.endsWith('.pdf')) {
          fileType = 'PDF';
        } else if (fileName.match(/\.(jpg|jpeg|png|gif)$/)) {
          fileType = 'IMAGE';
        } else if (fileName.match(/\.(xls|xlsx)$/i)) {
          fileType = 'EXCEL';
        } else if (fileName.match(/\.(doc|docx)$/i)) {
          fileType = 'WORD';
        } else if (fileName.match(/\.(mp4|avi|mov)$/)) {
          fileType = 'VIDEO';
        } else if (fileName.match(/\.(ppt|pptx)$/i)) {
          fileType = 'PPT';
        } else {
          fileType = 'DOCUMENT';
        }

        const contentStateWithEntity = editorState
          .getCurrentContent()
          .createEntity('EMBEDDED_LINK', 'IMMUTABLE', { src: fileDataUrl, type: fileType, name: fileName });

        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

        const newEditorState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');

        setEditorState(newEditorState);

        // Create a FormData object and append the file to it
        setFile(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const editorRef = React.createRef<Editor>();
  const [isFocused, setIsFocused] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const moveCursorToEnd = (editorState: any, setEditorState: any) => {
    const content = editorState.getCurrentContent();
    const blockArray = content.getBlocksAsArray();
    const lastBlock = blockArray[blockArray.length - 1];
    const lastBlockKey = lastBlock.getKey();
    const lastBlockLength = lastBlock.getLength();

    const selection = new SelectionState({
      anchorKey: lastBlockKey,
      anchorOffset: lastBlockLength,
      focusKey: lastBlockKey,
      focusOffset: lastBlockLength
    });

    const nextState = EditorState.forceSelection(editorState, selection);
    setEditorState(nextState);
  };

  useEffect(() => {
    if (showEditCancel) {
      moveCursorToEnd(editorState, setEditorState);
    }
  }, [activeMessageId]);

  // const insertImage = (editorState: EditorState, imageUrl: string, altImage: string) => {
  //   const contentState = editorState.getCurrentContent();
  //   const selectionState = editorState.getSelection();

  //   const contentStateWithEntity = contentState.createEntity('IMAGE', 'IMMUTABLE', { src: imageUrl, alt: altImage });
  //   const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  //   const newContentState = Modifier.insertText(contentState, selectionState, ' ', undefined, entityKey);

  //   const newEditorState = EditorState.push(editorState, newContentState, 'insert-characters');
  //   return newEditorState;
  // };

  const insertImage = (editorState: EditorState, imageUrl: string, altImage: string) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('IMAGE', 'IMMUTABLE', { src: imageUrl, alt: altImage });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const newEditorState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');

    return newEditorState;
  };

  const handlePaste = useCallback(
    (event: ClipboardEvent) => {
      const items = event.clipboardData?.items;
      if (!items) return;

      for (const item of items) {
        if (item.type.indexOf('image') === 0) {
          const file = item.getAsFile();
          if (file) {
            setFile(file);
            setFile(file);
            const blobUrl = URL.createObjectURL(file);
            const newEditorState = insertImage(editorState, blobUrl, '');
            setEditorState(newEditorState);
            event.preventDefault();
          }
        }
      }
    },
    [editorState]
  );

  useEffect(() => {
    const editorContainer = document.querySelector('.editorClassName');
    if (editorContainer) {
      editorContainer.addEventListener('paste', handlePaste as any);
    }
    return () => {
      if (editorContainer) {
        editorContainer.removeEventListener('paste', handlePaste as any);
      }
    };
  }, [handlePaste]);

  const handleKeyDown = (e: any) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      const rawContentState = convertToRaw(editorState.getCurrentContent());
      const draftToHtmlV = draftToHtml(rawContentState);
      if (draftToHtmlV === '<ul>\n<li></li>\n</ul>\n' || draftToHtmlV === '<ol>\n<li></li>\n</ol>\n') {
        setEditorState(EditorState.createEmpty());
      }
    }
  };

  const handleEditorStateChange = (editorState: EditorState) => {
    const contentState = editorState.getCurrentContent();

    const newState = EditorState.push(editorState, contentState, 'apply-entity');
    setEditorState(newState);
  };

  useEffect(() => {
    setEditorState(editorNew);
  }, [editorNew]);

  const handleReturn = (e: any) => {
    if (e.shiftKey && (e.code === 'Enter' || e.code === 'NumpadEnter')) {
      return false;
    } else {
      if (!e.shiftKey && (e.code === 'Enter' || e.code === 'NumpadEnter')) {
        if (contentText.trim().length > 0 || contentType.includes('atomic')) {
          handlePostMessage(editorState);
          const newEditorState = EditorState.createEmpty();
          const newStateWithCursorAtEnd = EditorState.moveFocusToEnd(newEditorState);
          setEditorState(newStateWithCursorAtEnd);
        }
        return true;
      } else {
        return true;
      }
    }
  };

  const compressImage = (file: any, maxWidth: any, maxHeight: any, quality: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let { width } = img;
          let { height } = img;

          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }

          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx?.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob: any) => {
              const compressedFile = new File([blob], file.name, {
                type: file.type,
                lastModified: file.lastModified
              });

              const compressedReader = new FileReader();
              compressedReader.onload = (compressedE: any) => {
                resolve(compressedE.target.result);
              };
              compressedReader.onerror = (error) => {
                reject(error);
              };
              compressedReader.readAsDataURL(compressedFile);
            },
            file.type,
            quality
          );
        };
        img.src = e.target.result;
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  const readAsDataURL = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        resolve({ data: { link: e.target.result } });
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleImageUpload = async (file: any, maxWidth: any = 400, maxHeight: any = 400) => {
    try {
      let quality = 0.5;

      if (file.size > 10 * 1024 * 1024) {
        quality = 0.2;
      }

      if (file.size > 15 * 1024 * 1024) {
        // Display a toast message here for images larger than 15MB
        toast.error('The max image size is 10MB');
        return null;
      }
      // if (file.size <= 1024 * 1024) {
      //   return await readAsDataURL(file);
      // }

      const imageDataUrl = await compressImage(file, maxWidth, maxHeight, quality);
      return { data: { link: imageDataUrl } };
    } catch (error) {
      console.error('Image upload error:', error);
      return { error: 'Image upload failed' };
    }
  };

  const embedCallback = (url: any) => {
    return url;
  };

  const findImageEntities = (contentBlock: any, callback: any, contentState: any) => {
    contentBlock.findEntityRanges((character: any) => {
      const entityKey = character.getEntity();
      return entityKey !== null && contentState.getEntity(entityKey).getType() === 'IMAGE';
    }, callback);
  };

  let emptyText: any;

  const checkForImagesAndFiles = () => {
    const contentState = editorState.getCurrentContent();
    const blocks = contentState.getBlocksAsArray();
    for (let block of blocks) {
      // const block = blocks[i];
      const entityKey = block.getEntityAt(0);
      if (entityKey) {
        const entity = contentState.getEntity(entityKey);
        if (['EMBEDDED_LINK', 'IMAGE'].includes(entity?.type)) {
          return true; // Found an image or file
        }
      }
    }

    return false; // No images or files found
  };

  const haveImageOrEntity = contentState.getBlockMap().some((block: any) => {
    emptyText = block;
    return (
      (block.getText() === '' && block.getType() === 'atomic') ||
      block.getEntityAt(0) !== null ||
      block.getType() === 'atomic'
    );
  });

  useEffect(() => {
    setImageEntities(haveImageOrEntity);
  }, [haveImageOrEntity]);

  const isEditorEmpty =
    emptyText?.getText() === '' && contentText.trim().length <= 0 && !imageEntities && !checkForImagesAndFiles();
  const extendedToolbarOptions = reactDraftWysiwygToolbarOptionsChat(handleImageUpload, embedCallback);
  const decorator = [{ strategy: findImageEntities, component: FituredImage }];

  // const removeBlock = (blockKey: any) => {
  //   const contentState = editorState.getCurrentContent();
  //   const newContentState = ContentState.createFromBlockArray(contentState.getBlockMap().delete(blockKey));

  //   setEditorState(EditorState.push(editorState, newContentState, 'remove-range'));
  // };

  const CustomBlockComponent = (props: any) => {
    const { block, contentState } = props;
    const { editorState, setEditorState } = props.blockProps;
    const entityKey = block?.getEntityAt(0);

    if (!block || !contentState || !entityKey) {
      return <div>Error: Undefined Entity or Block</div>;
    }
    // Check if entityKey is defined before accessing entity
    if (entityKey) {
      const entity = contentState.getEntity(entityKey);
      const entityData = entity.getData();
      const embeddedContent = entityData.src;
      const fileType = entityData.type;
      const removeBlock = (blocks: any) => {
        const blockKey = blocks.getKey();
        const blockMap = contentState.getBlockMap();
        const newBlockMap = blockMap.delete(blockKey);
        const newContentState = contentState.merge({
          blockMap: newBlockMap,
          selectionBefore: editorState.getSelection(),
          selectionAfter: editorState.getSelection()
        });
        const newEditorState = EditorState.push(editorState, newContentState, 'remove-range');
        setEditorState(newEditorState);
      };

      if (fileType === 'PDF') {
        return (
          <div>
            <embed src={embeddedContent} className={classes.fileTypePDf} />
            <button onClick={() => removeBlock(block)}>X</button>
          </div>
        );
      } else if (fileType === 'IMAGE') {
        return (
          <div>
            <img src={embeddedContent} alt="Uploaded Image" className={classes.fileTypeImage} />
            <button onClick={removeBlock}>X</button>
          </div>
        );
      } else if (fileType === 'DOCUMENT') {
        return (
          <div>
            <a href={embeddedContent} target="_blank" rel="noopener noreferrer">
              View Document
            </a>
            <button onClick={removeBlock}>X</button>
          </div>
        );
      } else if (fileType === 'VIDEO') {
        return (
          <div>
            <video controls className={classes.fileTypeVideo}>
              <source src={embeddedContent} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <button onClick={removeBlock}>X</button>
          </div>
        );
      } else {
        // Handle other file types here
        return <Typography>Unsupported file</Typography>;
      }
    } else {
      // Handle the case where entityKey is undefined
      return <div>Error: Undefined Entity</div>;
    }
  };

  // const CustomBlockComponent = (props: any) => {
  //   const { block, contentState } = props;
  //   const { editorState, setEditorState } = props.blockProps;
  //   const blockType = block.getType();

  //   const entityKey = block.getEntityAt(0);
  //   const entity = contentState.getEntity(entityKey);
  //   const entityData = entity.getData();

  //   if (blockType === 'atomic' && entity.getType() === 'FILE') {
  //     const embeddedContent = entityData.content;

  //     const removeBlock = () => {
  //       const blockKey = block.getKey();
  //       const blockMap = contentState.getBlockMap();
  //       const newBlockMap = blockMap.delete(blockKey);
  //       const newContentState = contentState.merge({
  //         blockMap: newBlockMap,
  //         selectionBefore: editorState.getSelection(),
  //         selectionAfter: editorState.getSelection()
  //       });

  //       const newEditorState = EditorState.push(editorState, newContentState, 'remove-range');
  //       setEditorState(newEditorState);
  //     };

  //     return (
  //       <div>
  //         <iframe src={embeddedContent} />
  //         <button onClick={removeBlock}>Remove</button>
  //       </div>
  //     );
  //   }

  //   return null; // Render other block types normally
  // };

  const blockRenderers = (contentBlock: any) => {
    const entityKey = contentBlock.getEntityAt(0);

    if (contentBlock.getType() === 'atomic' || contentBlock.text === '📷') {
      const entity = contentState.getEntity(entityKey);
      const entityData = entity.getData();
      if (entity.getType() === 'EMBEDDED_LINK') {
        const fileType = entityData.type;
        const embeddedContent = entityData.src;

        return {
          component: CustomBlockComponent,
          editable: false,
          props: {
            fileType,
            embeddedContent,
            contentState,
            editorState,
            setEditorState
          }
        };
      } else {
        return {
          component: ImageBlock,
          editable: false,
          props: {
            block: contentBlock,
            contentState,
            blockProps: {
              editorState,
              setEditorState
            }
          }
        };
      }
    }

    return null;
  };

  const blockRenderer = (contentBlock: any) => {
    const entityKey = contentBlock.getEntityAt(0);
    if (
      contentBlock.getType() === 'atomic' ||
      contentBlock.text === '📷' ||
      contentBlock.getType() === 'EMBEDDED_CONTENT'
    ) {
      return {
        component: ImageBlock,
        editable: false,
        props: {
          block: contentBlock,
          contentState,
          blockProps: {
            setUpdateFile,
            setFile,
            setImageEntities,
            imageEntities,
            editorState,
            setEditorState
          }
        }
      };
    }
    return null;
  };

  const genKey = () => {
    return Math.random().toString(36).substr(2, 9);
  };

  return isLoading ? (
    <ChatEditorLoader />
  ) : (
    <Box className={classes.chatArea}>
      <Box className={classes.chatTextBox}>
        <Box className={hidePlaceholder} onKeyDown={handleKeyDown}>
          <Editor
            readOnly={selectedChatUser.communicationId ? true : false}
            onBlur={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
            customDecorators={decorator}
            customBlockRenderFunc={blockRenderer}
            ref={editorRef}
            handlePastedText={() => false}
            editorState={editorState}
            editorStyle={{ fontFamily: 'Open Sans, sans-serif' }}
            // wrapperStyle={{ padding: 0, border: "none" }}
            toolbarClassName="toolBarStyle"
            wrapperClassName="wrapperClassName p-0 b-0"
            editorClassName="chatEditorClass"
            toolbar={extendedToolbarOptions}
            placeholder={`Message ${channelName}`}
            handleReturn={handleReturn}
            onEditorStateChange={handleEditorStateChange}
            //   //TODO: Need to save editor state in redux
            //   // dispatch(chatEditorActions.atnSetEditorState({status: "new", threadId:threadValue,value: state }))
            //   setEditorState(state);
            // }}
          />
        </Box>

        {/* Send Icon */}
        <Box position="absolute" bottom="3px" right="10px" className="cursor-pointer">
          <IconButton onClick={showEditCancel ? handleEditCancel : null}>
            {showEditCancel && <CloseIcon fontSize="small" />}
          </IconButton>
          <IconButton
            sx={{
              background: '#0071A9 !important',
              border: '1px solid #0071A9',
              boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.08)',
              borderRadius: '8px',
              width: '32px',
              height: '32px'
            }}
            color="primary"
            // disabled={disableSendButton || contentText.trim().length <= 0}
            disabled={disableSendButton || isEditorEmpty}
            onClick={() => {
              if (!selectedChatUser.communicationId) {
                handlePostMessage(editorState);
                const newEditorState = EditorState.createEmpty();
                const newStateWithCursorAtEnd = EditorState.moveFocusToEnd(newEditorState);
                setEditorState(newStateWithCursorAtEnd);
              }
            }}
          >
            <Send
              fontSize="small"
              sx={{
                color: '#D9D9D9',
                width: '16.65px',
                height: '16.06px'
              }}
            />
          </IconButton>
        </Box>

        {/* File upload */}
        <Box position="absolute" bottom={0} sx={{ backgroundColor: '#F8F8F8', borderBottomLeftRadius: '8px' }}>
          <input
            type="file"
            accept=".ppt, .pptx, .pdf, .doc, .docx, .xls, .xlsx, .mp4, .avi, .mov .mp3, .jpg, .jpeg, .png, .gif"
            className={classes.fileUploadInput}
            onChange={handleFileUpload}
            placeholder="file"
            id="file-upload-input"
            key={file ? 'file-upload-input' : 'file-upload-input-reset'}
            disabled={selectedChatUser.communicationId ? true : false}
          />
          <label htmlFor="file-upload-input">
            <IconButton component="span" sx={{ '&:hover': { backgroundColor: 'transparent', color: '#000' } }}>
              <AttachFileIcon sx={{ width: '18px' }} />
            </IconButton>
          </label>
        </Box>
      </Box>
    </Box>
  );
};

export default ChatEditorComponent;
