import React, { useEffect } from 'react';

interface CustomFontFamilyDropdownProps {
    onChange: (font: string) => void;
    currentState: {
        fontFamily?: string;
    };
}

const CustomFontFamilyDropdown: React.FC<CustomFontFamilyDropdownProps> = ({
    onChange,
    currentState,
}) => {
    const fonts = ['Open Sans']//, 'Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana']
    /** Ensure "Open Sans" is selected if no font is explicitly set */
    useEffect(() => {
        if (!currentState?.fontFamily) {
            onChange('Open Sans');
        }
    }, [currentState, onChange]);

    return (
        <div className='d-none'>
            <label htmlFor="font-family-dropdown" className='d-none'>Choose Font Family:</label>
            <select
                id="font-family-dropdown"
                value={currentState?.fontFamily || 'Open Sans'}
                onChange={(e) => onChange(e.target.value)}
            >
                {fonts.map((font) => (
                    <option key={font} value={font}>
                        {font}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default CustomFontFamilyDropdown;
