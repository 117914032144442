import React from 'react';
import { Box, SxProps, Theme } from '@mui/material';
import '../assets/style/sass/htmlContent.scss';

interface IRenderHTML {
    htmlContent: string;
    className?: string;
    fallbackText?: string;
    sx?: SxProps<Theme>;
}

const RenderHTML: React.FC<IRenderHTML> = ({
    htmlContent,
    className = 'htmlContent',
    sx,
    fallbackText,
}) => {
    const urlRegex = /(?<!['"])(http(s)?:\/\/)?[a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}[-a-zA-Z0-9@:%_\+.~#?&=,;\/-]*(?![^<]*>)/g;

    const processContent = (content: string) =>
        content
            .replace(urlRegex, (url) =>
                `<a href="${url.startsWith('http') ? url : `http://${url}`}" target="_blank" rel="noopener noreferrer">${url}</a>`
            )
            .replace(/<p[^>]*>\s*<\/p>/g, '<br/>') // Empty <p> tags
            .replace(/<p[^>]*>\s*(<span[^>]*>\s*<\/span>\s*)*<\/p>/g, '<br/>') // <p> with empty spans
            .replace(/<br>\s*<\/p>/g, '<br/><br/></p>') // <br> before </p>
            .replace(/<br>\s*<\/span>/g, '<br/><br/></span>') //<br> before </span>
            .replace(/<br>\s*<\/strong>/g, '<br/><br/></strong>') //<br> before </strong>
            .replace(/<br>\s*<\/em>/g, '<br/><br/></em>') //<br> before </em>
            .trim();

    const sanitizedContent = htmlContent?.trim()
        ? processContent(htmlContent)
        : `<span class='fallbackText'>${fallbackText}</span>`;

    return (
        <Box
            width="100%"
            className={className}
            dangerouslySetInnerHTML={{ __html: sanitizedContent }}
            sx={sx}
        />
    );
};

export default RenderHTML;
